/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  //bugfixing

  // Navigation
  $(".menu-toggle").click(function () {
    $("body").toggleClass("navigation-open");
    $("nav#navigation").toggleClass("open");
    $(this).toggleClass("exit");
    $("span.text").toggleClass("active");
  });

  $(window).scroll(function () {
    if ($(this).scrollTop() >= 215 - 30) {
      $(".toggle").addClass("sticky");
    } else {
      $(".toggle").removeClass("sticky");
    }
  });

  $(".slider-section").each(function () {
    var swiperEl = $(this).find(".swiper").get(0);

    var swiperPrevMobile = $(this).find(".slider-section__button--prev").get(0);
    var swiperNextMobile = $(this).find(".slider-section__button--next").get(0);

    var swiperPrev = $(this).find(".slider-button-prev").get(0);
    var swiperNext = $(this).find(".slider-button-next").get(0);

    var swiper = new Swiper(swiperEl, {
      // Optional parameters
      loop: false,
      slidesPerView: "auto",
      spaceBetween: 30,
      slidesOffsetAfter: 50,
      navigation: {
        prevEl: swiperPrevMobile,
        nextEl: swiperNextMobile,
      },
      breakpoints: {
        600: {
          spaceBetween: 40,
        },
        1024: {
          spaceBetween: 50,
          slidesOffsetAfter: 0,
          navigation: {
            prevEl: swiperPrev,
            nextEl: swiperNext,
          },
        },
      },
    });
  });

  // REcaptcha
  var $window = $(window);
  var $element = $(".g-recaptcha");
  var windowsize = $window.width();

  $element.attr("data-size", "compact");

  //Input styling
  $("input.ninja-forms-field").on(
    "keyup keydown keypress change paste",
    function () {
      if ($(this).val() === "") {
        $(this).removeClass("filled");
      } else {
        $(this).addClass("filled");
      }
    }
  );
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
